import React from 'react';
import PropTypes from 'prop-types';
import {BannerStyled} from './styles';
import SliderComponent from './slidershow';

const Banner = ({tagName, arrow}) => {
  return (
    <BannerStyled>
      <SliderComponent tagName={tagName} arrow={arrow} />
    </BannerStyled>
  );
};

export default Banner;

Banner.propTypes = {
  tagName: PropTypes.string,
  arrow: PropTypes.bool,
};
