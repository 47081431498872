import React from 'react';
import {Col, Row} from 'react-awesome-styled-grid';

import {ClientStyled} from './styles';
import thumbnailVideo from '../../images/Banners/thumbnail-video.jpg';
import {fetchBanners} from '../../API/makers/adminBanners';
import {generateImageUrl} from '../../utils/productHelper';

// TODO this video needs to be change by the cloudfront video info
const STATIC_VIDEO = "https://d3kc934orq20pw.cloudfront.net/Video%207.1.mp4"
import coverVideo from '../../images/Banners/cover-video.png';

export const SliderComponent = () => {
  const {banners, isLoading} = fetchBanners('home-main-video');
  const urlVideoHome =
    'https://e-makers-production.s3.amazonaws.com/videos/Video+Promo+Maker+V5_12.mp4';

  return (
    <ClientStyled>
      {/* {!isLoading && ( */}
      {(
        <Row>
          <Col className="clip-section-video">
            <video
              muted
              autoPlay={false}
              loop={false}
              poster={
                coverVideo
              }
              controls
            >
              <source
                src={STATIC_VIDEO}
                type="video/mp4"
              />
              {/* <source
                src={
                  banners[0]
                    ? generateImageUrl(banners[0].video_url)
                    : urlVideoHome
                }
                type="video/mp4"
              /> */}
            </video>
          </Col>
        </Row>
      )}
    </ClientStyled>
  );
};

export default SliderComponent;
