import React from 'react';
import {Link} from 'gatsby';
import PropTypes from 'prop-types';
import {Text} from 'styled-typography';
import {Col, Row} from 'react-awesome-styled-grid';

import {BannerLookBookStyle} from './styles';
import {generateImageUrl} from '../../utils/productHelper';
import {fetchBanners} from '../../API/makers/adminBanners';

const BannerLookBook = ({tagName}) => {
  const {banners} = fetchBanners(tagName) || null;
  return (
    <Link to={'/lookbook'}>
      {banners[0] && (
        <BannerLookBookStyle>
          <Row className="container-banner">
            <Col className="container-banner-image">
              <img
                src={`${banners && generateImageUrl(banners[0]?.image_url)}`}
              />
            </Col>
            <Col className="container-banner-info">
              <Text as="h2">Look Book </Text>
              <Text as="h3"> Show more</Text>
            </Col>
          </Row>
        </BannerLookBookStyle>
      )}
    </Link>
  );
};

export default BannerLookBook;

BannerLookBook.propTypes = {
  tagName: PropTypes.string,
};
