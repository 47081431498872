import styled from 'styled-components';
import {config} from 'react-awesome-styled-grid';
export default styled.div`
  cursor: pointer;
  .container-banner {
    display: flex;
    flex-direction: column;
    position: relative;
    .container-banner-image {
      img {
        z-index: -100;
        width: 100%;
        height: 100%;
      }
    }
    .container-banner-info {
      display: flex;
      z-index: 100;
      position: absolute;
      flex-direction: column;
      justify-content: center;
      top: 0;
      bottom: 0;
      width: 100%;
      h2 {
        display: flex;
        justify-content: center;
        font-size: 40px;
        ${props => config(props).media['sm']`
          font-size: 66px;
        `}
      }
      .line {
        display: flex;
        justify-content: center;
        padding-top: 5px;
        width: 100%;
        z-index: inherit;
        ${props => config(props).media['sm']`
          padding-top: 22px;
        `}
        &::before {
          content: ' ';
          width: 70%;
          border-bottom: ${({divider}) => divider && 'solid 1px #ffffff'};
          ${props => config(props).media['sm']`
            width: 40%;
          `}
        }
      }
      > p {
        display: flex;
        justify-content: center;
        z-index: inherit;
        font-size: 18px;
        text-align: center;
        padding-top: 5px;
        padding-left: 65px;
        padding-right: 65px;
        ${props => config(props).media['md']`
          padding-top: 15px;
          font-size: 20px;
        `}
      }
    }
  }
  .skeletonStyle {
    width: 100%;
    min-height: 480px;
    @media (max-height: 1080px) {
      height: ${({tag}) =>
        tag !== 'collections-banner' ? 'calc(100vh - 150px)' : '450px'};
    }
    @media (max-height: 1024px) and (orientation: landscape) {
      height: ${({tag}) =>
        tag !== 'collections-banner' ? 'calc(100vh - 850px)' : '450px'};
    }
    @media (max-height: 1440px) and (orientation: landscape) {
      height: ${({tag}) =>
        tag !== 'collections-banner' ? 'calc(100vh - 340px)' : '450px'};
    }
    ${props => config(props).media['md']`
      height: ${({tag}) =>
        tag !== 'collections-banner' ? 'calc(100vh - 250px)' : '450px'};  
    `}
  }
  &:after {
    display: flex;
    content: '';
    position: absolute;
    background: rgba(0, 0, 0, 0);
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 0;
  }
`;
