import React from 'react';
import SliderComponent from './slidershow';
import {Col, Row} from 'react-awesome-styled-grid';
import {Heading} from 'styled-typography';
import {CollectionsStyled} from './styles';
import CardCollections from '../core/CardCollections';
import useImgixImage from '../../hooks/useImgixImage';

const Collections = () => {
  const banner3 = useImgixImage('/images/trending-search.png');

  return (
    <CollectionsStyled>
      <Row>
        <Col>
          <Heading as="h3" level="4">
            Maker’s collections
          </Heading>
        </Col>
      </Row>
      <Row justify="center">
        <Col xs={3.5} sm={7} md={4.5} lg={7} xl={6} align="center">
          <SliderComponent />
        </Col>
        <Col xs={3.5} sm={7} md={4.5} lg={4} xl={3} align="center">
          <CardCollections background={banner3}></CardCollections>
        </Col>
      </Row>
    </CollectionsStyled>
  );
};

export default Collections;
