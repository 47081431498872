import React from 'react';
import {Link} from 'gatsby';
import {Heading, Text} from 'styled-typography';
import {Col, Row} from 'react-awesome-styled-grid';

// import ButtonProduct from '../core/ButtonProduct';
import Card from '../core/Card';
import {MoreProductsStyle} from './styles';
import useImgixImage from '../../hooks/useImgixImage';
import SkeletonMoreProducts from './SkeletonMoreProducts';
import {fetchProductsWithFilter} from '../../API/makers/products';

const MoreProduct = () => {
  const perPage = 'per_page=6';
  const {products, isLoading} = fetchProductsWithFilter(perPage);
  const logoEmpty = useImgixImage('/images/logo-empty.png');

  if (isLoading) {
    return <SkeletonMoreProducts />;
  }

  const isActive = products.filter(item => item.active === true);

  return (
    <MoreProductsStyle>
      <Row>
        <Col>
          <Heading as="h3" level="4">
            Explore more products
            {/* <Hidden sm xs>
              <Link to={'/all-shoes'}>
                <ButtonProduct width="112px" height="30px">
                  SEE ALL +
                </ButtonProduct>
              </Link>
            </Hidden> */}
          </Heading>
        </Col>
      </Row>
      <Row>
        {!products.length && !isLoading ? (
          <Col align="center">
            <img src={logoEmpty} className="empty-state"></img>
          </Col>
        ) : (
          isActive.map(product => (
            <Row align="center" key={product.id} className="wrap__cards">
              <Card
                productId={product.id}
                img={
                  product.master.images[0] &&
                  product.master.images[0].product_url
                }
                productName={product.name}
                product={product}
                Price={product.master.display_price}
              ></Card>
            </Row>
          ))
        )}
      </Row>
      <Row justify="center">
        <button className="buttonMobile">
          <Link to={'/all-shoes'}>
            <Text level="5" width="92px" height="30px">
              SEE ALL +
            </Text>
          </Link>
        </button>
      </Row>
    </MoreProductsStyle>
  );
};

export default MoreProduct;
