import React from 'react';

import {PopUpStyled, ImagePopUp} from './styles';
import useImgixImage from '../../hooks/useImgixImage';
import {useGlobalContext} from '../../context';
import {navigate} from 'gatsby';
import {fetchPopups} from '../../API/makers/adminBanners';
import {generateImageUrl} from '../../utils/productHelper';

const PopUp = () => {
  const iconClose = useImgixImage('/images/close-icon.svg');
  const {setModalPopupsIsOpen} = useGlobalContext();
  const {popups, isLoading} = fetchPopups();

  return (
    <>
      {isLoading ? (
        'loading...'
      ) : (
        <PopUpStyled>
          <img
            className="close-modal"
            src={iconClose}
            onClick={() => setModalPopupsIsOpen(false)}
          />
          <ImagePopUp
            image1={popups && generateImageUrl(popups[0]?.image_url)}
            image2={popups && generateImageUrl(popups[0]?.mobile_url)}
            onClick={() => navigate(popups && popups[0].url)}
          />
        </PopUpStyled>
      )}
    </>
  );
};

export default PopUp;
